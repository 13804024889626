<template>
  <div class="card_stack_carousel__wrap"
       :style="height">
    <!-- <div v-for="(item, i) in items"
         :key="i"
         class="card_stack_carousel_item card"
         :class="getCarouselState(i)">

      <div class="card_stack_carousel_item_img__wrap">
        <img :src="item.img">
      </div>

      <div class="card_stack_carousel_item_info__wrap">
        <span>
          {{item.info}}
        </span>
      </div>
    </div> -->

    <slot></slot>

    <div class="arrow__wrap left"
         @click="calCurrent('-')">
      <i class="fas fa-arrow-left"></i>
    </div>
    <div class="arrow__wrap right"
         @click="calCurrent('+')">
      <i class="fas fa-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.min.css'
export default {
  name: 'card-stack-carousel',
  props: {
    height: {
      type: String,
      default: '800px',
    }
  },
  computed: {
    carouselLength () {
      return this.carousels.length
    }
  },
  mounted () {
    this.updateItems()
  },
  data () {
    return {
      current: 0,
      turnLeft: false,
      items: [
        { img: 'https://img2.baidu.com/it/u=681136033,2021176936&fm=26&fmt=auto&gp=0.jpg', info: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ab, quod!' },
        { img: 'https://img1.baidu.com/it/u=1955829655,3313450489&fm=26&fmt=auto&gp=0.jpg', info: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat, eum. Non officia porro animi illo vitae commodi aut, minima at.' },
        { img: 'https://img0.baidu.com/it/u=1854076588,3154421244&fm=26&fmt=auto&gp=0.jpg', info: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat, eum. Non officia porro animi illo vitae commodi aut, minima at.' },
        { img: 'https://img0.baidu.com/it/u=2269042853,265109011&fm=26&fmt=auto&gp=0.jpg', info: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat, eum. Non officia porro animi illo vitae commodi aut, minima at.' },
      ],
      carousels: []
    }
  },
  methods: {
    // 获取所有name=card-stack-carousel-item的子组件
    updateItems () {
      this.carousels = this.$children.filter(child => child.$options.name === 'card-stack-carousel-item')
    },
    // 计算current的值
    calCurrent (symbol) {
      const current = this.current
      if (symbol === '+') this.current = (current + 1) % this.carouselLength
      if (symbol === '-') this.current = current > 0 ? (current - 1) % this.carouselLength : this.carouselLength - 1
    }
    // getCarouselState (index) {
    //   if (!this.turnLeft) {
    //     console.log(index, this.current)
    //     if (index === this.current) return 'moveRightIn acitve'
    //     if (this.current === (index + 1) % this.carouselLength) return 'moveLeftOut'
    //     return 'none'
    //   } else {
    //     if (index === this.current) return 'moveLeftIn acitve'
    //     if (index === (this.current + 1) % this.carouselLength) return 'moveRightOut'
    //     return 'none'
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.card_stack_carousel__wrap {
  display: flex;
  padding: 0 15vw;
  // height: 40vh;
  min-height: 450px;
  position: relative;

  .card_stack_carousel_arrow__wrap {
    z-index: -1;
    position: absolute;
    width: 80vw;
    height: 100%;
  }
  .arrow__wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    i {
      font-size: 1.5em;
    }
    &.left,
    &.right {
      padding: 1em;
      border-radius: 50%;
      background: #fff;
      box-shadow: 2px 2px 10px #ddd;
      cursor: pointer;
    }
    &.left {
      left: 12%;
    }
    &.right {
      right: 12%;
    }
  }
}
</style>