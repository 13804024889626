<template>
  <div class="card_stack_carousel_item card"
       :class="getState">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'card-stack-carousel-item',
  created () {
    this.$parent && this.$parent.updateItems();
  },
  computed: {
    itemIndex () {
      const parent = this.$parent;
      if (parent && parent.$options.name === 'card-stack-carousel') {
        return parent.carousels.indexOf(this)
      }
      throw new Error('没有card-stack-carousel根元素')
    },
    getState () {
      const parent = this.$parent
      const index = this.itemIndex
      if (index < 0) return ''
      if (!parent.turnLeft) {
        if (index === parent.current) return 'moveRightIn acitve'
        if (parent.current === (index + 1) % parent.carouselLength) return 'moveLeftOut'
        return 'none'
      } else {
        if (index === parent.current) return 'moveLeftIn acitve'
        if (index === (parent.current + 1) % parent.carouselLength) return 'moveRightOut'
        return 'none'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$width_in: 800px;
$width_out: 850px;
$height_in: 400px;
$height_out: 370px;
.card {
  background: #fff;
  box-shadow: 5px 5px 15px #ccc;
}
.card_stack_carousel_item {
  box-sizing: border-box;
  padding: 2em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $width_out;
  height: $height_out;
  border-radius: 0.5em;
  cursor: pointer;
  &.moveLeftIn {
    animation: moveLeftIn 0.25s linear forwards;
  }
  &.moveRightIn {
    animation: moveRightIn 0.25s linear forwards;
  }
  &.moveLeftOut {
    animation: moveLeftOut 0.25s linear forwards;
  }
  &.moveRightOut {
    animation: moveRightOut 0.25s linear forwards;
  }
  &.none {
    animation: none;
  }
  &.active {
    z-index: 10;
    width: $width_in;
    height: $height_in;
  }

  @keyframes moveLeftIn {
    from {
      left: 50%;
      z-index: 0;
      width: $width_out;
      height: $height_out;
    }
    50% {
      left: 40%;
    }
    to {
      left: 50%;
      z-index: 10;
      width: $width_in;
      height: $height_in;
    }
  }

  @keyframes moveRightOut {
    from {
      left: 50%;
      z-index: 10;
      width: $width_in;
      height: $height_in;
    }
    50% {
      left: 60%;
    }
    to {
      left: 50%;
      z-index: 1;
      width: $width_out;
      height: $height_out;
    }
  }

  @keyframes moveRightIn {
    from {
      left: 50%;
      z-index: 0;
      width: $width_out;
      height: $height_out;
    }
    50% {
      left: 60%;
    }
    to {
      left: 50%;
      z-index: 10;
      width: $width_in;
      height: $height_in;
    }
  }

  @keyframes moveLeftOut {
    from {
      left: 50%;
      z-index: 10;
      width: $width_in;
      height: $height_in;
    }
    50% {
      left: 40%;
    }
    to {
      left: 50%;
      z-index: 1;
      width: $width_out;
      height: $height_out;
    }
  }
}
</style>